import _api from './api.js';
import { env } from './environment.js'
const endpointTimezone = env.api_base_url+"/api/v2/timezone.php";
const endpointUtente = env.api_base_url+"/api/v2/utente.php";
export default {
    loadTimezones: async function () {
        const url = endpointTimezone;
        const result = await _api(url,'get')
        if (!result.success) return [];
        return result.data;
    },

    loadOrari: async function () {
        const url = endpointTimezone+"?orari";
        const result = await _api(url,'get')
        if (!result.success) return [];
        return this.makeTimeSlots(result.data);
    },

    makeTimeSlots: function (orari) {
        return orari.map((el)=>{
            const ora = +(el.split(":")[0]);
            const oraFine = +ora + 2;
            let flag;
            if (ora > 12) {
                flag = " del giorno precedente";
            } else {
                flag = " del giorno dell'allenamento";
            }
            const label = `${ora}-${oraFine} ${flag}`;

            return { 
                value: el, 
                label: label,
            }
        });
    },

    loadPreferences: async function () { 
        const url = endpointUtente+"?campi=permessi";
        const result = await _api(url,'get');
        if (!result.success) return;
        return result.data;
    },

    savePreferences: async function (utente_id, timeslot, timezone) {
        if (!utente_id) return;
        const payload = {
            orario_invio_email: timeslot,
            fuso_orario: timezone,
        };
        const url = endpointUtente+`?id=${utente_id}`;
        return _api(url, 'put', payload)
    },
}
