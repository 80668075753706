<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    id: {
      default: Date.now()
    },
    type: {
      type: String,
      default: "text"
    },
    classes: {
      type: Object
    },
    placeHolder: {
      type: String
    },
    rules: {
      type: String
    },
    name: {
      type: String
    },
    answerContainerClass: {
      type: String
    },
    answerClass: {
      type: String
    },
    labelClass: {
      type: String
    },
    dataSource: {
      type: Array
    },
    dataTextField: {
      type: String
    },
    dataValueField: {
      type: String
    },
    answer: {}
  },
  watch: {
  },
  data() {
    return {
    };
  },
  computed: {
      htmlAnswer: function () {
          return this.answer;
      },
  },
  methods: {
    handleInput() {
    }
  }
};
</script>
