<template>
    <v-card class="user-preferences">
        <v-container>

            <v-row>
                <v-col class="pa-2">
                    <v-select
                        :items = "timeSlots"
                        item-text = "label"
                        item-value = "value"
                        label="Orario di ricezione"

                        @change="savePreferences" v-model="selectedTimeSlot">
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="pa-2">

                    <v-autocomplete
                        v-model="selectedTimezone"
                        :items="timezones"
                        item-text='label'
                        item-value='value'
                        label="Fuso orario"
                    ></v-autocomplete>

                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import _timezone from "@mixins/timezone.js"

export default {
    components: {
    },
    props: [
        "utente_id",
    ],
    watch: {
        utente_id: function () {
            this.init();
        },
    },
    data() {
        return {
            selectedTimeSlot: '06:00',
            selectedTimezone: null,
            timeSlots: [],
            timezones: [], // Sarà popolato con l'elenco dei fusi orari
        }
    },
    methods: {
        init: async function () {
            const pref = await this.loadPreferences();
            this.selectedTimeSlot = pref && pref.orario_invio_email || '06:00'
            this.selectedTimezone = pref && pref.fuso_orario || null
        },
        loadTimezones: async function () {
            this.timezones = await _timezone.loadTimezones();
        },

        loadOrari: async function () {
            this.timeSlots = await _timezone.loadOrari();
        },

        setTimezone: function (timezone) {
            this.selectedTimezone = timezone.value;
            this.savePreferences();
        },

        loadPreferences: async function () { 
            return _timezone.loadPreferences();
        },
        savePreferences: async function () {
            return _timezone.savePreferences(this.utente_id, this.selectedTimeSlot, this.selectedTimezone);
        },
    },

    mounted: async function () {
        this.loadTimezones();
        this.loadOrari();
        this.init();
    },
}
</script>

<style scoped>
.v-col {
    width: 100%;
}
</style>
