<template>
    <div class="msp-risposte-acquisto" >
        <v-card class="msp-risposte-acquisto" >
            <v-toolbar
                    dark
                    color="primary"
                    class="sticky"
                    >
                    <v-btn
                            icon
                            dark
                            @click="dismissModal"
                            >
                            <v-icon>mdi-close</v-icon>
                    </v-btn>
                <v-toolbar-title>Dettaglio</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text >
                <div v-if="schema" class="msp-form-acquisto-container" >

                    <answers-creator
                            :schema="schema"
                            :answers="answers"
                            validations-container-class="risposte-container"
                            validation-container-class="risposta-container"
                            answer-container-class="risposta"
                            answer-class="risposta-risposta"
                            label-class="risposta-label"
                            />

                </div>
            </v-card-text>
        </v-card>


    </div>
</template>

<script>
import api from "@mixins/api.js";
import env from "@mixins/environment.js"
import AnswersCreator from '@mobilesrc/AnswersCreator/AnswersCreator.vue';
const component = {
    components: {
        AnswersCreator,
    },
    mixins: [
    env,
    ],
    props: [
    ],
    computed: {
    },
    data() {
        return {
            data: {},
            schema: null,
            answers: null,
        }
    },
    mounted: function () {
        this.init();
    },

    created() {
        this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
            if (toParams.id !== previousParams.id) {
                this.init();
            }
        })
    },
    methods: {
        init: function () {
            let acquisto_id = this.$route.params.id;
            this.acquisto_id = acquisto_id;
            this.loadAcquisto(acquisto_id);
        },

        dismissModal () {
            this.$store.dispatch("acquisto/setDialog", false);
        },

        loadAcquisto: async function (acquisto_id) {
            let url = this.api_base_url+"/api/v2/ecommerce.php?risposte&acquisto_id="+acquisto_id;
            const result = await api(url, "GET", null);
            if (result.success) {
                const questionario = result.data;
                    this.schema = questionario.form_fields;
                // gestisce errore di vecchie risposte: se sono in formato array, restituisce la prima 
                if (!questionario.risposte) {
                    this.answers = null;
                } else if (!questionario.risposte.length) {
                    this.answers = questionario.risposte;
                } else {
                    this.answers = questionario.risposte[0];
                }
            }
            return Promise.resolve(result);
        },

    }
}
export default component;
</script>

<style lang="scss">
.msp-risposte-acquisto {
    .sticky {
        position: sticky;
        top: 0;
    }

    .risposta-container {
        background: #fff;
        &:nth-child(odd) {
            background: var(--col-grigio-chiarissimo);
        }
        .risposta {
            display: flex;
            flex-direction: column;
            padding: 7px;

            p {
                margin: 0;
            }
        }
        .risposta-label {
            flex: 1;
        }

        .risposta-risposta {
            font-weight: bold;
        }
    }
}
</style>
