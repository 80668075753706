<template>
  <div class="account">
    <msp-app-bar title="Profilo utente"></msp-app-bar>
    <v-container>
      <v-row dense>
 <v-col
          cols="12"
          sm="6"
      >
       <v-btn
      block
      color="primary"
      @click="openDialogChangePassword()"
    >
      Cambia password
    </v-btn>
        <v-dialog
            v-model="fieldsPassword.editing"
            ref="'dialog-change-password" 
            persistent
            >
            <v-form  ref="form_cambia_password" v-model="valid" lazy-validation @submit.prevent="changePassword">
                <v-card>
                <v-card-title>Cambia password</v-card-title>
                    <v-card-text class="py-10"><v-text-field
                    v-for="(field, key) in fieldsPassword.fields"
                    :key = "key"
                    :append-icon="field.show ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, checkPassword]"
                    :type="field.show ? 'text' : 'password'"
                    :label="field.label"
                    v-model="field.value"
                    @click:append="toggleShow(field)" >
                    </v-text-field></v-card-text>
                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="closeDialogChangePassword()"
                    >
                      Annulla
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="changePassword()"
                      :disabled = "!valid"
                    >
                      OK
                    </v-btn>
                </v-card-actions>
                </v-card>

            </v-form>

        </v-dialog>
      </v-col>
      </v-row>
       
      <v-row dense >
  
      <v-col
          cols="12"
          sm="6"
          v-for="field in filteredFields"
          :key="field.key"
        >
            <template v-if="fieldExists(field)">
                <template v-if="field.type==='readonly'">
                    <label v-text="field.label"> </label>
                    <span v-text="profilo[field.key]"> </span>
                    </template>
                    <template v-if="field.type==='radio'">
                <v-radio-group
                :label="field.label"
                v-model="profilo[field.key]"
                @change="updateField(field)" 
                row >
                <v-radio 
                v-for="option in field.options"
                :key="option.value"
                :value="option.value"
                :label="option.label" >
                </v-radio>
                
                </v-radio-group>
                </template>
                <template v-if="field.type==='text'">
                  <v-text-field
                    v-model="profilo[field.key]"
                    :label="field.label"
                    :readonly="field.readonly"
                    :disabled="field.disabled"
                    @change="updateField(field)"
                  ></v-text-field>
                </template>
                <template v-if="field.key==='peso'">
                    <template v-if="profilo[field.key]">
                   <v-text-field
                    v-model="profilo[field.key]"
                    :label="field.label + ' (' + profilo.peso_data_dmy +')'"
                    readonly
                  ></v-text-field>
                  </template>

                    <v-btn @click="addPesoFromFab">Aggiungi il tuo peso di oggi</v-btn>
                  
                </template><template v-if="field.type==='date'">

                        <v-dialog
                          v-model="field.editing"
                          :ref="'dialog-'+field.key"
                          :return-value.sync="profilo[field.key]"
                          width="290px"
                          persistent
                        >
                          <template v-slot:activator="{ on, attrs }">
                          
                            <v-text-field
                              :value="formatCalendarDay(profilo[field.key])"
                              label="Data di nascita"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                           :ref="'picker-'+field.key"
                            v-model="profilo[field.key]"
                              :max="maxDate"
                              :min="minDate"
                              :first-day-of-week="1"
                              locale="it-it"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              @click="field.editing = false"
                            >
                              Annulla
                            </v-btn>
                            <v-btn
                              color="primary"
                              @click="updateDialog(field)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>



                </template>
            </template>

        </v-col>
      <v-col
          cols="12"
          sm="6"
      >
       <v-btn
      outlined
      block
      color="error"
      @click="deleteAccount()"
    >
      Elimina account
    </v-btn>
      </v-col>

</v-row>
    </v-container>
</div>

</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";
import fields from "@data/profilo.js"
import api from "@mixins/api.js"
import env from "@mixins/environment.js"
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import {  mapGetters } from 'vuex'

const component = {
  mixins: [
    env,
    allenamentoCalcoli,
  ],
  components: {
    mspAppBar,

  },
  data () {
    return {
        valid: false,
        rules: {
            required: value => !!value || 'Richiesto.',
            min: v => (v && v.length >= 5) || 'Min 5 caratteri',
        },
        maxDate: null,
        minDate: null,
        profilo: {},
        isAtleta: false,
        fieldsPassword: {
            editing: false,
            fields: {
                password: {
                    "value": "",
                    "label": "Password",
                    "table": "utente",
                    "type": "text",
                    "show": false,
                    "editing": false,
                }, 
                confirm: {
                    "value": "",
                    "label": "Conferma password",
                    "table": "utente",
                    "type": "text",
                    "show": false,
                    "editing": false,
                }, 
             },
        },
        fields: fields,
    }
  },
  mounted () {
    this.init();

  },
  watch: {
      fields: {
        handler: function (fields) {
            const key = "data_nascita";
            const field = fields && fields.find(el=>el.key===key);
            if (!field) {
                return;
            }
            field && setTimeout(() => (this.$refs["picker-"+key][0].activePicker = 'YEAR'))
        },
        deep: true,
      },
    },
  computed: {
    ...mapGetters('atleti', [
      'atleta',
    ]),
    ...mapGetters('utente', [
      'utente',
    ]),
    checkPassword: function () {
        return ((!!this.fieldsPassword.fields.password.value && (this.fieldsPassword.fields.password.value === this.fieldsPassword.fields.confirm.value)) || 'La password è diversa dalla conferma');
    },
    filteredAcquisti () {
      let acquisti = [...this.acquisti];
      if (!this.showAcquisti) {
        acquisti = acquisti.slice(0, this.limitAcquisti);
      }
      return acquisti;
    },
    textBtnAcquisti () {
      return (this.showAcquisti) ? 'Nascondi' : 'Mostra tutti';
    },
    filteredFields: function () {
        if (!(this.fields && this.utente)) return [];
        const ruolo = (this.utente.is_atleta) ? "atleta" : "allenatore";
        return this.fields[ruolo];
    },
  },
  methods: {
      addPesoFromFab: function () {
          this.openPeso(null);
      },

      openPeso: function () {
        this.$store.dispatch("calendario/setDialog",true);
        const newPage = {
            path: "/calendario/misurazione/add",
            query: {
                "filtered_options": ['peso']
            }
        };
        this.$router.push(newPage);
      },

      fieldExists: function (field) {
          let isAtleta = this.isAtleta && field.table === 'atleta';
          let isOther = field.table !== 'atleta';
          return isAtleta || isOther;
      },

      changePassword: function () {
          if (!this.$refs.form_cambia_password.validate()) {
              return;
          }
          if (this.checkPassword !== true) {
              return;
          }

          let data = {
              password: this.fieldsPassword.fields.password.value,
              verifica: this.fieldsPassword.fields.confirm.value,
          };
          let url = this.api_base_url+"/api/v2/utente.php?password";
          return new Promise((resolve,reject)=>{

              api(url,"put",data)
              .then((result)=>{
                  if (result.success) {
                      this.logout();
                      resolve(result);
                  } else {
                      resolve(null);
                  }
              })
              .catch((error)=>{
                  reject(error);
              })
          });
      },
      toggleShow: function (field) {
          field.show = !field.show;
      },
      logout: function () {
          this.$router.replace("/logout");
      },
      emptyPassword: function () {
          Object.entries(this.fieldsPassword.fields)
          .forEach((el)=>{
              el[1].value = ""; 
          });
      },
      closeDialogChangePassword: function () {
          this.emptyPassword();
          this.fieldsPassword.editing = false;
      },
      openDialogChangePassword: function () {
          this.fieldsPassword.editing = true;
      },
      deleteAccount: function () {
          this.$router.push("delete");
      },
      subtractYears: function (years) {
          let date = new Date();
          date.setFullYear(date.getFullYear() - years);
          return date.toISOString().substr(0,10);
      },
      setMinDate: function () {
          this.minDate = this.subtractYears(90);
      },
      setMaxDate: function () {
          this.maxDate = this.subtractYears(5);
      },
      updateDialog: function (field) {
          // il ref è un array, probabilmente perché è all'interno di un loop
          this.$refs['dialog-'+field.key][0].save(this.profilo[field.key]);
          this.updateField(field);
          field.editing = false;
      },
      init: function () {
          this.setMinDate();
          this.setMaxDate();
          this.loadUtente().then(
          (result)=>{
              if (result.success) {
                  this.profilo = {
                      ...this.profilo,
                  ...result.data};
              }
          }
          );
          this.loadAtleta().then(
          (result)=>{
              if (result.success) {
                  this.isAtleta = true;
                  this.profilo = {
                      ...this.profilo,
                  ...result.data};
              }
          }
          );

      },
      updateField: function (field) {
          if (field.table==="atleta") {
              let data = {
                  [field.key]: this.profilo[field.key],
              }
              this.updateAtleta(data);
          }
          if (field.table==="utente") {
              let data = {
                  [field.key]: this.profilo[field.key],
              }
              this.updateUtente(data);
          }
      },

      updateUtente: function (data) {
          let url = this.api_base_url+"/api/v2/utente.php";//+params;
          return new Promise((resolve,reject)=>{
              api(url,"put",data)
              .then((result)=>{
                  if (result.success) {
                      resolve(result);
                  } else {
                      resolve(null);
                  }
              })
              .catch((error)=>{
                  reject(error);
              })
          });
      },

      convertDate (date) {
          return (new Date(date)).toLocaleDateString("it");
      },
      loadUtente () {
          let url = this.api_base_url+"/api/v2/utente.php";//+params;

          return new Promise((resolve,reject)=>{
              api(url,"get",null)
              .then(
              (result)=>{
                  resolve(result);
              })
              .catch((error)=>{
                  reject(error);
              })
          });
      },
      loadAtleta () {
          let url = this.api_base_url+"/api/v2/atleta.php";//+params;

          return new Promise((resolve,reject)=>{
              api(url,'get', null)
              .then((result)=>{
                  resolve(result);
              })
              .catch((error)=>{
                  reject(error);
              })
          });
      },
      updateAtleta: function (data) {
          let url = this.api_base_url+"/api/v2/atleta.php";//+params;
          return new Promise((resolve,reject)=>{
              api(url,"put",data)
              .then((result)=>{
                  if (result.success) {
                      resolve(result);
                  } else {
                      resolve(null);
                  }
              })
              .catch((error)=>{
                  reject(error);
              })
          });
      },

  },
}
export default component
</script>
<style lang="scss">
.account {
}
</style>
