<template>
    <div :class="validationsContainerClass">
      <div
        v-for="(i, index) in items"
        :key="index"
        :class="validationContainerClass"
      >
          <component
            v-bind:is="bestInput(i.type)"
            :type="i.type"
            :label="i.label"
            :name="i.key"
            :id="i.id"
            :answer="i.answer"
            :classes="i.classes"
            :answer-container-class="answerContainerClass"
            :answer-class="answerClass"
            :label-class="labelClass"
            :data-source="i.dataSource"
            :data-text-field="i.dataTextField"
            :data-value-field="i.dataValueField"
          ></component>
          
      </div>
    </div>
</template>
<script>
import TextInput from "./TextInput.vue";
import TextArea from "./TextArea.vue";
import CheckBox from "./CheckBox.vue";
import MultiCheckBox from "./MultiCheckBox.vue";
import Radio from "./Radio.vue";
import Info from "./Info.vue";
export default {
  props: {
    /** Form Schema */
    schema: {
      type: Array
    },
    answers: {
      type: Object
    },
    validationsContainerClass: {
      type: String
    },
    validationContainerClass: {
      type: String
    },
    answerContainerClass: {
      type: String
    },
    labelClass: {
      type: String,
      default: '',
    },
    answerClass: {
      type: String,
      default: '',
    },
  },
  watch: {
  },
  data() {
    return {
    };
  },
  components: {
      TextInput,
      TextArea,
      CheckBox,
      MultiCheckBox,
      Radio,
      Info,
  },

  computed: {
      items: function () {
          let items = this.schema.map((question)=>{
              let answer = this.answers[question.key];
              let item = {
                  ...question,
                  answer: answer
              };
              return item;
          });
          return items;
      },
  },
  methods: {

      findQuestion: function (key) {
          if (!(this.schema && this.schema.length)) return false;
          return this.schema.find(el=>el.key===key);
      },


      bestInput(type) {
          if (type == "textarea") return TextArea;
          if (type == "checkbox") return CheckBox;
          if (type == "multicheckbox") return MultiCheckBox;
          if (type == "radio") return Radio;
          if (type == "info") return Info;
          return TextInput;
      }
  }
};
</script>
