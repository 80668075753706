<template>
  <div class="form-creator-radio" :class="answerContainerClass">
  <span :class="labelClass">{{label}}</span>
  <span :class="answerClass">{{radioAnswer}}</span>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";

export default {
  extends: InputCommons,
  computed: {
      radioAnswer: function () {
          return this.findRadioAnswer(this.answer);
      },
  },
  methods: {
      findRadioAnswer: function (id) {
          if (!(this.dataSource && this.dataSource.length)) return false;
          let answer = this.dataSource.find(el=>el.id === id);
          if (!answer) return false;
          return answer.value;
      },
  },
};
</script>
<style lang="scss">
.form-creator-radio {
    label {
        display: flex;
        align-items: center;
    }

    input {
        margin: 0;
    }
}
</style>
