<template>
  <fieldset :class="answerContainerClass">
  <legend>{{label}}</legend>
  <div class="options">
      <template v-for="option in dataSource" >
      <p v-if="inAnswer(option.id)" :key="option.id">
      <strong>
          {{ option.value }}
      </strong>
      </p>
      </template>
  </div>
  </fieldset>
</template>
<script>

import InputCommons from "./InputCommons.vue";
import multiCheckBox from "@src/js/vue/include/AnswersCreator/mixins/multiCheckBox.js";
export default {
  extends: InputCommons,
  mixins: [
  multiCheckBox,
  ],
};
</script>
