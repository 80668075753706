<template>
  <div class="account-connessioni">
    <msp-app-bar title="Connessioni"></msp-app-bar>
      <v-container max-width="450px">
      <div v-if="atleta" class="pa-colonne">
    <v-card>
        <v-card-text>Collega i tuoi account per avere i dati sincronizzati con Coachpeaking...</v-card-text>
    </v-card>
    <v-card>
        <v-card-title class="mt-5">
            <span class="img-connessione" 
                :class="{disconnesso: !autorizzazioni.garmin.autorizzato}"
            ><img
              @click="disconnettiOrAutorizzaGarmin()" src="/img/garmin-button.png" class="garmin img-connessione" alt="tasto garmin"></span>
        </v-card-title>
    </v-card>
    <v-card>
        <v-card-title class="mt-5">
            <span class="img-connessione"
              :class="{disconnesso: !autorizzazioni.strava.autorizzato}"
            ><img
              @click="disconnettiOrAutorizzaStrava()" src="/img/strava-button.svg" class="strava img-connessione" alt="tasto strava"></span>
        </v-card-title>
        <v-card-text v-show="!autorizzazioni.strava.isLoading" class="text-center">
    <msp-recupera-dati-strava class="mt-5" :connesso="autorizzazioni.strava.autorizzato" :atleta-id="atleta.id" />
        </v-card-text>
    </v-card>
    <v-card>
        <v-card-title class="mt-5">
            <span class="img-connessione"
              :class="{disconnesso: !autorizzazioni.polar.autorizzato}"
            ><img
              @click="disconnettiOrAutorizzaPolar()" src="/img/polar-button.svg" class="polar img-connessione" alt="tasto polar"></span>
        </v-card-title>
    </v-card>
  </div></v-container>
  </div>

  </template>

  <script type="module">
/* TODO: convertire in funzione js

vedi appunti su 
materiale/connessioni.txt
con tutti gli endpoints.
Verificare se alcuni endpoint si possono convertire da v1 a v2
Alcuni endpoint sono sul sito di terze parti. Verificare callback (deve tornare sulla webapp mobile).
Alcuni endpoint hanno dei percorsi relativi.



$CP_user =  $_SESSION['utente_id'];
$clientId = "21197";
$callbackUrl = "https://app.coachpeaking.com/strava/callback.php";
$http_get = "http://www.strava.com/oauth/authorize?client_id=" . $clientId . "&approval_prompt=force&state=" . $CP_user. "&response_type=code&scope=activity:read,activity:read_all,profile:read_all&redirect_uri=https://app.coachpeaking.com/strava/callback.php";
*/
  import mspAppBar from "@components/MspAppBar.vue";
  import env from "@mixins/environment.js";
  import _api from "@mixins/api.js";
  import _storage from "@mixins/storage.js";
  import mspRecuperaDatiStrava from "@components/MspRecuperaDatiStrava.vue";
  import CP_CONFIG from "@src/js/msp/config.js";
  const component = {
    mixins: [
      env,
    ],
    components: {
      mspAppBar,
        mspRecuperaDatiStrava,
    },
    data () {
        return {
        atleta: null,
        licenza: null,
        revealLicenza: false,
        showAcquisti: false,
        limitAcquisti: 5,
        acquisti: [],
        popupWorkout: null,
        autorizzazioni: { 
            garmin: {
                autorizzato: false,
                oauth_token: false,
                isLoading: false,
            },
            polar: {
                autorizzato: false,
                oauth_token: false,
                isLoading: false,
            },
            strava: {
                autorizzato: false,
                oauth_token: false,
                isLoading: false,
            },
        },

        message: {
            type: 'success',
            text: '',
        },
        authWindow: null,
        v_oauth_token: null,
        isAuthorized: false,
        isCompleted: false,
        }
    },
    mounted () {

        this.verificaAutorizzazioneGarmin();
        this.verificaAutorizzazioneStrava();
        this.verificaAutorizzazionePolar();

        window.addEventListener("message", (event) => {
          if (!this.popupWorkout) {
            return;
          }
          if (event.origin !== window.location.origin)
          {
            // return;

          }
          
          if (typeof event.data === 'object') {
              if (event.data.type === 'MSP_AUTH_POLAR') {
            this.autorizzazioni.polar.isLoading = false;
                this.popupWorkout.close();
                this.aggiungiAutorizzazionePolar(event.data.data);
              }
          }

          if (event.data === 'MSP_AUTH_STRAVA') {
            this.popupWorkout.close();
            this.autorizzazioni.strava.isLoading = false;
            this.autorizzazioni.strava.autorizzato = true;
          }
          
          if (event.data === 'MSP_AUTH_GARMIN') {
            this.popupWorkout.close();
            this.autorizzazioni.garmin.isLoading = false;
            this.autorizzazioni.garmin.autorizzato = true;
          }

          if (event.data === 'MSP_AUTH_ERROR') {
            this.popupWorkout.close();
            this.autorizzazioni.garmin.isLoading = false;
            this.autorizzazioni.garmin.autorizzato = false;
          }

          if (event.data === 'MSP_SENT_GARMIN') {
            this.popupWorkout.close();
            this.autorizzazioni.garmin.isLoading = false;
          }

        }, false);



      this.init();

    },
    computed: {
      filteredAcquisti () {
        let acquisti = [...this.acquisti];
        if (!this.showAcquisti) {
          acquisti = acquisti.slice(0, this.limitAcquisti);
        }
        return acquisti;
      },
      textBtnAcquisti () {
        return (this.showAcquisti) ? 'Nascondi' : 'Mostra tutti';
      }
    },
    methods: {
      init () {
        this.loadAtleta().then(
          (result)=>{
            if (result.success) {
              this.atleta = {...result.data};
            }
          }
        );

      },
      convertDate (date) {
        return (new Date(date)).toLocaleDateString("it");
      },
      loadAtleta () {
        let url = this.api_base_url+"/api/v2/atleta.php";//+params;

        return new Promise((resolve,reject)=>{
            _api(url, "GET")
            .then((result)=>{
                if (result.success) {
                    resolve(result);
                } else {
                    resolve(null);
                }
            })
            .catch((error)=>{
                reject(error);
            });
        });
    },

    disconnettiOrAutorizzaGarmin: function () {
        if (this.autorizzazioni.garmin.isLoading) return;
        if (this.autorizzazioni.garmin.autorizzato) {
            this.disconnettiGarmin();
        } else {
            this.autorizzaGarmin();
        }
    },

    disconnettiOrAutorizzaStrava: function () {
        if (this.autorizzazioni.strava.isLoading) return;
        if (this.autorizzazioni.strava.autorizzato) {
            this.disconnettiStrava();
        } else {
            this.autorizzaStrava();
        }
    },

    disconnettiOrAutorizzaPolar: function () {
        if (this.autorizzazioni.polar.isLoading) return;
        if (this.autorizzazioni.polar.autorizzato) {
            this.disconnettiPolar();
        } else {
            this.autorizzaPolar();
        }
    },

    checkTokenPolarExport: async function () {
        this.autorizzazioni.polar.isLoading = true;
      return new Promise((resolve, reject)=>{

        const url = this.api_base_url+"/api/v2/workout.php?token_polar";

        _api(url, "GET")
        .then((result)=>{
            this.autorizzazioni.polar.isLoading = false;
            if (result.success) {
              resolve(result.data);
            } else {
              resolve(false);
            }
          }
          )
          .catch((error)=>{
            this.autorizzazioni.polar.isLoading = false;
            reject(error);
          })
      });
    },
    
    checkTokenStravaExport: async function () {
        this.autorizzazioni.strava.isLoading = true;
      return new Promise((resolve, reject)=>{

        const url = this.api_base_url+"/api/v2/workout.php?token_strava";

        _api(url, "GET")
        .then((result)=>{
            this.autorizzazioni.strava.isLoading = false;
            if (result.success) {
              resolve(result.data);
            } else {
              resolve(false);
            }
          })
          .catch((error)=>{
            this.autorizzazioni.strava.isLoading = false;
            reject(error);
          })
      });
    },
    checkTokenGarminExport: async function () {
            this.autorizzazioni.garmin.isLoading = true;

      return new Promise((resolve, reject)=>{

        const url = this.api_base_url+"/api/v2/workout.php?token";
        _api(url, "GET")
        .then((result)=>{
            this.autorizzazioni.garmin.isLoading = false;
            if (result.success) {
              resolve(result.data);
            } else {
              resolve(false);
            }
          })
          .catch((error)=>{
            this.autorizzazioni.garmin.isLoading = false;
            reject(error);
          });
      });

    },

    verificaAutorizzazionePolar: async function () {
        this.autorizzazioni.polar.isLoading = true;

      let token = await this.checkTokenPolarExport();

      if (token) {
        this.autorizzazioni.polar.isLoading = false;
        this.autorizzazioni.polar.autorizzato = true;
      } else {
        this.autorizzazioni.polar.isLoading = false;
        this.autorizzazioni.polar.autorizzato = false;
      }
    },

    verificaAutorizzazioneStrava: async function () {
        this.autorizzazioni.strava.isLoading = true;

      let token = await this.checkTokenStravaExport();

      if (token) {
        this.autorizzazioni.strava.isLoading = false;
        this.autorizzazioni.strava.autorizzato = true;
      } else {
        this.autorizzazioni.strava.isLoading = false;
        this.autorizzazioni.strava.autorizzato = false;
      }
    },

    verificaAutorizzazioneGarmin: async function () {

        this.autorizzazioni.garmin.isLoading = true;
      let token = await this.checkTokenGarminExport();
      this.autorizzazioni.garmin.oauth_token = token;

      if (token) {
        this.autorizzazioni.garmin.isLoading = false;
        this.autorizzazioni.garmin.autorizzato = true;
      } else {
        this.autorizzazioni.garmin.isLoading = false;
        this.autorizzazioni.garmin.autorizzato = false;
      }
    },

    disconnettiPolar: function () {
        this.autorizzazioni.polar.isLoading = true;
        let url = this.api_base_url+"/api/v2/polar_registration.php";

        return new Promise((resolve,reject)=>{
            _api(url, "DELETE")
            .then((result)=>{
                if (result.success) {
                    this.autorizzazioni.polar.autorizzato = false;
                    this.autorizzazioni.polar.isLoading = false;
                    resolve(result);
                } else {
                    this.autorizzazioni.polar.isLoading = false;
                    resolve(null);
                }
            })
            .catch((error)=>{
                this.autorizzazioni.polar.isLoading = false;
                reject(error);
            });
        });
    },
    disconnettiStrava: function () {
        this.autorizzazioni.strava.isLoading = true;
        let url = this.api_base_url+"/api/v2/strava_registration.php";

        return new Promise((resolve,reject)=>{
            _api(url, "DELETE")
            .then((result)=>{
                if (result.success) {
                    this.autorizzazioni.strava.isLoading = false;
                    this.autorizzazioni.strava.autorizzato = false;
                    resolve(result);
                } else {
                    this.autorizzazioni.strava.isLoading = false;
                    resolve(null);
                }
            })
            .catch((error)=>{
                this.autorizzazioni.strava.isLoading = false;
                reject(error);
            });
        });
    },
      disconnettiGarmin: function () {
              this.autorizzazioni.garmin.isLoading = true;
        let url = this.api_base_url+"/api/v2/garmin_registration.php";

      return new Promise((resolve,reject)=>{
          _api(url, "DELETE")
        .then((result)=>{
            if (result.success) {
              this.autorizzazioni.garmin.isLoading = false;
                this.autorizzazioni.garmin.autorizzato = false;
              resolve(result);
            } else {
              this.autorizzazioni.garmin.isLoading = false;
              resolve(null);
            }
        })
        .catch((error)=>{
              this.autorizzazioni.garmin.isLoading = false;
            reject(error);
          });
      });
    },
    autorizza: function () {
        console.log("TODO: autorizza");
    },
    autorizzaPolar: function () {
        this.autorizzazioni.polar.isLoading = true;
         const polarClientId = "218aba0e-d43c-4cea-9025-dde39f3ad7a8";
         const origin = window.location.origin;
         const url = "https://flow.polar.com/oauth2/authorization?response_type=code&state=mobile|"+origin+"&client_id="+polarClientId;
         const windowFeatures = "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=400,height=600";
         this.popupWorkout = window.open(url, 'popupWorkout', windowFeatures);
    },
    aggiungiAutorizzazionePolar: function (token) {
        this.autorizzazioni.polar.isLoading = true;
        return new Promise((resolve,reject)=>{
            const url = this.api_base_url+"/api/v2/polar_registration.php";
            const data = {
                polar: token,
            };
            _api(url, "POST", data)
            .then((result)=>{

                if (result.success) {
                    this.autorizzazioni.polar.autorizzato = true;
                    this.autorizzazioni.polar.isLoading = false;
                    resolve(result);
                } else {
                    this.autorizzazioni.polar.isLoading = false;
                    resolve(null);
                }
            })
            .catch((error)=>{
                this.autorizzazioni.polar.isLoading = false;
                reject(error);
            });
        });
    },
    autorizzaStrava: function () {
        this.autorizzazioni.strava.isLoading = true;
         let utente_id =  this.atleta.id;
         const clientId = CP_CONFIG.STRAVA_CLIENT_ID;
         const callbackUrl = this.api_base_url + "/strava/auth_workout.php?origin=" + window.location.origin;
         const url = "http://www.strava.com/oauth/authorize?client_id=" + clientId + "&approval_prompt=force&state=" + utente_id+ "&response_type=code&scope=activity:read,activity:read_all,profile:read_all&redirect_uri=" + callbackUrl;

         const windowFeatures = "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=400,height=600";
         this.popupWorkout = window.open(url, 'popupWorkout', windowFeatures);
    },
    autorizzaGarmin: function () {
        this.autorizzazioni.garmin.isLoading = true;
        const auth_token = _storage.getAuthToken();
        const url = this.api_base_url+"/garmin/auth_workout.php?origin="+window.location.origin+"&auth_token="+auth_token;
        const windowFeatures = "menubar=no,location=no,resizable=no,scrollbars=yes,status=no,width=400,height=600";
        this.popupWorkout = window.open(url, 'popupWorkout', windowFeatures);
    },
    toggleAutorizzaGarmin: function () {
        if (this.autorizzazioni.garmin.autorizzato) {
            this.disconnettiGarmin();
        } else {
            this.autorizzaGarmin();
        }
    },


  },
}
export default component
</script>
<style lang="scss">
.account-connessioni {
    .img-connessione {
        position: relative;
        &.disconnesso {
            filter: grayscale(1);
            opacity: 0.2;
        }
        &:not(.disconnesso):before {
            z-index: 1;
            content: var(--ico-check);
            font-family: msp;
            position: absolute;
            pointer-events: none;
            top: 0;
            right: 0;
            background: var(--col-msp);
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2em;
            height: 2em;
            border-radius: 50%;
            transform: translate(50%,-50%);
        }
        cursor: pointer;
        max-width: 200px;
        display: block;
        margin: auto;
        width: 100%;
        img {
            display: block;
            width: 100%;

    }
    }
    .v-btn {
        margin: auto;
    }
}
</style>

