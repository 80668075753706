export default {
    data: function () {
        return {
            content: [],
        }
    },
    watch: {
        value: function () {
        }
    },
    methods: {
      inAnswer: function (id) {
          return (this.answer && (id in this.answer));
      },

    },
};

