<template>
  <div class="form-creator-radio" :class="answerContainerClass">
  <p :class="labelClass">{{label}}</p>
  <p class='pre' :class="answerClass" v-html="answer"></p>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons
};
</script>
