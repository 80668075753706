<template>
    <div>
        <v-card v-cloak v-if="!licenza || licenza > -1" :class="classLicenza" class="hide-cloak mb-5" color="error" dark>
            <v-card-title>Licenza scaduta</v-card-title>
        </v-card>
    </div>
</template>

<script type="module">
import api from "@mixins/api.js"
import env from "@mixins/environment.js"


const component = {
    mixins: [
    env,
    ],
  components: {
  },
  data: function () {
    const data = {
        licenza: null,
        loadingLicenza: true,
    }
    return data;
  },
  computed: {
      classLicenza: function () {
          if (this.loadingLicenza) {
              return "hide";
          }
      },
  },
  mounted() {
    this.init();
  },
  methods: {
    init: function () {
        this.getLicenza()
        .then((result)=>{
            this.loadingLicenza = false;
            if (result.success) {
                this.licenza = this.findLicenzaAttiva(result.data);
            } else {
                this.licenza = -1;
            }
        });
    },
    
    findLicenzaAttiva: function (licenze) {
        const found = licenze.find((li)=>(li.countdown > 0));
        return found;
    },

    getLicenza: function () {
        this.loadingLicenza = true;
        let url = this.api_base_url+"/api/v2/ecommerce.php?licenze";
        return api(url, "get");
    },

  }
}
export default component
</script>
<style lang="scss">
[v-cloak].hide-cloak,
.hide {
    display: none !important;
}
</style>
