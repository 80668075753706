<template>
  <div :class="answerContainerClass">
  <span :class="labelClass">{{ label }}</span>
  <span :class="answerClass">{{ answer }}</span>
  </div>
</template>
<script>
import InputCommons from "./InputCommons.vue";
export default {
  extends: InputCommons
};
</script>
