<template>
    <div class="settimana-tipo">
    <msp-app-bar title="Settimana tipo"></msp-app-bar>
    <v-container>
      <div class="mx-n3 mb-3 px-3 py-3 totale-settimana">
          <span>Totale ore settimanali:</span> <v-chip color="white">{{totaleSettimana}}</v-chip>
      </div>
      <v-row v-show="!editing" dense>
      <v-list flat v-show="!editing" class="view">
            <v-list-item :style="styleListItem" class="item" :key="giorno.giorno" v-for="giorno in settimanaTipo">
            <div class="giorno">
                {{getGiorno(giorno.giorno).abbr}}
            </div>
            <template v-if="hasSport(giorno)">
           <div  :class="classSport(sport)" v-for="sport in giorno.sports" :key="sport.sport_id">
            <i class="icona msp-icon" :class="'msp-icon-sport-'+sport.sport_id"></i>

            <div class="minuti">{{ore(sport.minuti)}}</div>
            </div>
            </template>

            <div class="riposo" v-else>
                Riposo
            </div>
                
            </v-list-item>

    <div v-if="utente && utente.is_atleta" class="mt-6 stepper-actions">
        <v-btn
            block
              color="primary"
              @click="edit"
            >
              Modifica
            </v-btn>
    </div>
        </v-list>
        </v-row>
        <v-row v-show="editing && utente && utente.is_atleta" dense >
      
          <v-col
            >
      <v-stepper v-model="e1" ref="stepper">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 0"
            step="1" >
            Sport praticati
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 1"
            step="2"
          >
            Giorni della settimana
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step 
          :complete="e1 > 2"
          step="3">
            Ore giornaliere
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="0">
            <v-card
              class=""
            >
    <v-card-title> Sport praticati</v-card-title>

    <v-card-text>
        <v-checkbox
                    v-for="sport in config.sports"
                    :key = "sport.id"
                  v-model="idSportPraticati"
                  :label="sport.descrizione"
                  :value="+sport.id"
                  hide-details
                ></v-checkbox>
                </v-card-text>
    <v-card-actions>
            
            <v-btn
            block
              color="primary"
              @click="updateSportsAndNextStep();"
              :disabled="!idSportPraticati.length"
            >
              Successivo
            </v-btn>


    </v-card-actions>
                </v-card>
          </v-stepper-content>

          <v-stepper-content step="1">
            <v-card
              class=""
            >
    <v-card-title>Giorni della settimana</v-card-title>

    <v-card-text>
        <v-checkbox
                    v-for="giorno in config.giorni"
                    :key = "giorno.id"
                  v-model="idGiorni"
                  :label="giorno.nome"
                  :value="+giorno.id"
                  hide-details
                ></v-checkbox>
                </v-card-text>
    <v-card-actions>
            <v-btn
            block
              color="primary"
              @click="updateGiorniAndNextStep();"
              :disabled="!idGiorni.length"
            >
              Successivo
            </v-btn>


    </v-card-actions>
    </v-card>
          </v-stepper-content>
          <v-stepper-content
          v-for="(giorno, index) in giorni"
          :key="'giorno-' + giorno.id"
          :step="2+index">
                <v-card>
                <v-card-title class="giorni-titolo">
                {{giorno.nome}}
                <span class="small">Ore praticate</span>
                <v-chip>{{totaleOre(giorno)}}</v-chip> 
                </v-card-title>

                <v-card-text>
                Indica il tempo che dedichi realmente ad ogni sport in questo giorno. 
              </v-card-text>
    <div
    :class="{'off':!sport.minuti}" v-for="sport in giorno.sports"
    :key="'sport-'+giorno.id+'-'+sport.sport_id">


                  <v-card-title class="my-0 py-0">{{getSport(sport.sport_id).descrizione}}<v-spacer></v-spacer> <v-chip class="sport-ore" v-if="sport.minuti">{{ore(sport.minuti)}}</v-chip></v-card-title>

                  <v-slider
                  min="0"
                  max="360"
                  step="30"
                  thumb-size="40"
                  :color="getSport(sport.sport_id).colore"
                  v-model="sport.minuti"
                  thumb-label
                  class="mx-3"
                  >
                  <template v-slot:thumb-label="{ value }">
                    {{ore(value)}}
                    </template>

              </v-slider>
              </div>
                <v-card-actions>
                <v-btn
                v-if = "!isLast"
                block
                color="primary"
                @click="nextStep()"
                >
                Successivo
                </v-btn>
                <v-btn
                v-if = "isLast"
                block
                color="primary"
                @click="saveSettimanaTipo()"
                >
                Fine
                </v-btn>
                </v-card-actions>
                </v-card>
          </v-stepper-content>

          

         


        </v-stepper-items>
      </v-stepper>
            </v-col>
          

    </v-row>
        </v-container>
        <msp-app-footer></msp-app-footer>
    </div>

    </template>

    <script type="module">
    import mspAppBar from "@components/MspAppBar.vue";
    import mspAppFooter from "@components/MspAppFooter.vue";
    import env from "@mixins/environment.js"
    import api from "@mixins/api.js"
    import sports from "@cached/sport.json"
    import giorni from "@cached/giorni.json"
    import { mapGetters } from 'vuex'
    let durate = [0,30,60,90,120,150,180,210,240,270,300,330,360];
    const component = {
      mixins: [
        env,
      ],
      components: {
        mspAppBar,
        mspAppFooter,

      },
      data () {
        return {
            editing: false,
            sportPraticati: [],
            settimanaTipo: [],
            giorni: [],
            idSportPraticati: [],
            idGiorni: [],

            config: {
                durate: durate,
                giorni: giorni,
                sportPraticati: [],
                sports: [
                "podismo",
                "ciclismo",
                "nuoto",
                "palestra"
                ].map((el)=>sports
                .find(sport=>sport.codice===el))
                .sort(this.sortByPosizione)
        },
        e1: 0,
        e2: 0,
    }
  },
  mounted () {
    this.init();

  },
  watch: {
    },
  computed: {
    ...mapGetters('atleti', [
      'atleta',
    ]),
    ...mapGetters('utente', [
      'utente',
    ]),
      totaleSettimana: function () {
          let totale = 0;
          if (!this.settimanaTipo) {
              return totale;
          }
          let minuti = this.settimanaTipo
            .filter(giorno=>giorno.sports&&giorno.sports.length)
            .map(giorno=>giorno.sports)
            .flat()
            .reduce((acc,sport)=>{
                return acc+sport.minuti;
            }, 0);
        this.$emit("update-totale", minuti);          
        return this.ore(minuti);
      },
        styleListItem: function () {
            let style = "--num-sport:" + this.idSportPraticati + 1;
            return style;
        },
        isLast: function () {
            if (!this.$refs.stepper) return false;
            let nGiorni = this.giorni.length;
            let last = this.$refs.stepper.steps.length + nGiorni -2;
            return (nGiorni && this.e1===last);
        },
        isLastDay: function () {
            let nGiorni = this.giorni.length;
            let last = this.e2 === nGiorni - 1;
            return (nGiorni && last);
        },
  },
    methods: {
        init: function () {
          this.resetSteps();
          this.loadSettimanaTipo()
          .then((result)=>{
                if (result.success) {
                    let settimanaTipo = result.data.settimana || [];
                    let sports = result.data.sports || [];
                    this.settimanaTipo = settimanaTipo;
                    this.setSportPraticati(sports); this.setGiorni(settimanaTipo);
                    if (!(settimanaTipo && settimanaTipo.length)) {
                        this.edit();
                    }
                } else {
                        this.edit();
                }
            });
        },
        loadSettimanaTipo: function () {
            if (!this.atleta) return Promise.resolve({
                success:false,
                data: null,
            });
            let atleta_id = this.atleta.id;
            let url = this.api_base_url+"/api/v2/settimana_tipo.php?atleta_id="+atleta_id;
            return api(url,"get",null)
        },

        resetSteps: function () {
            this.e1 = 0;
            this.e2 = 0;
        },

        classSport: function (sport) {
            let a_class = ["giorno-sport"];
            a_class.push("giorno-sport-"+sport.sport_id);
            if (!sport.minuti) {
                a_class.push("vuoto");
            }
            return a_class.join(" ");
        },
        
        hasSport: function (giorno) {
            return (giorno.sports && giorno.sports.length);
        },

        edit: function () {
            this.editing = true;
        },

        view: function () {
            this.editing = false;
        },

        setGiorni: function (settimana) {
            this.idGiorni = settimana.filter(el=>el.sports&&el.sports.length).map(el=>el.giorno);
        },

        updateSportPraticati: function (sport_ids) {
            let url = this.api_base_url+"/api/v2/settimana_tipo.php?sport_praticati";
            //TODO: aggiungere livello
            let sports = this.config.sportPraticati.map((sport)=>{
                let result = {
                    sport_id: sport.sport_id,
                    abilitato: (sport_ids.indexOf(sport.sport_id) !== -1) ? 1 : 0,
                    livello_atleta: 1,
                }
                return result;
            });


            let data = {
                sports: sports,
            };
            api(url, "put", data)
            .then((result)=>{
                if (result.success) {
                  this.idSportPraticati = sports.filter(el=>el.abilitato===1).map(el=>+el.sport_id);
                }
            })
        },
        setSportPraticati: function (sportPraticati) {
          this.config.sportPraticati = sportPraticati;
          this.sportPraticati = sportPraticati;
          this.idSportPraticati = sportPraticati
            .filter(el=>el.abilitato)
            .map(el=>+el.sport_id)
            .sort(this.sortByPosizione);
        },
        nextStep: function () {
            this.e1 += 1;
        },
        nextDay: function () {
            this.e2 += 1;
        },
        makeSport: function (data) {
            return {
                sport_id: data.sport_id,
                minuti: +data.minuti,
            }
        },
        makeGiorno: function (data) {
            return {
                giorno: +data.id,
                sports: data.sports.map(this.makeSport),
            }
        },

        saveSettimanaTipo: async function () {
            this.view();
            await this.save();
            this.init();
        },
        save: function () {
            let giorni = this.config.giorni.map((giorno)=>{
                let giornoAggiunto = this.giorni.find((g)=>g.id == giorno.id);
                let result;
                if (giornoAggiunto) {
                    result = {
                        sports: giornoAggiunto.sports,
                        giorno: +giorno.day,
                        riposo: 0,
                    }
                } else {
                    result = {
                        sports: null,
                        giorno: +giorno.day,
                        riposo: 1,
                    }
                }
                return result;
            });
            let data = {
                giorni: giorni,
            };

            let url = this.api_base_url+"/api/v2/settimana_tipo.php";
            return api(url,"put",data);
        },
        makeOreSport: function (sport_id) {
            return {
                sport_id: sport_id,
                minuti: 0,
            }
        },
        makeOreSports: function (sport_ids) {
            return sport_ids.map(this.makeOreSport);
        },
        getGiorno: function (day) {
            let giorno = this.config.giorni.find(el=>el.day==day);
            return giorno;
        },
        getSport: function (sport_id) {
            let sport = this.config.sports.find(el=>el.id==sport_id);
            if (!sport) {
                return {
                    descrizione: "manca",
                }
            }
            return sport;
        },
      sortBySportId: function (a,b) {
          let sport_a = this.getSport(a.sport_id);
          let sport_b = this.getSport(b.sport_id);

            return (+sport_a.posizione>+sport_b.posizione)?1:-1;
        },
      sortByPosizione: function (a,b) {
            return (+a.posizione>+b.posizione)?1:-1;
        },
      sortById: function (a,b) {
            return (+a.id>+b.id)?1:-1;
        },
      sortGiorni: function () {
            return this.giorni.sort(this.sortById);
        },
        updateSportsAndNextStep: function () {
            this.updateSports();
            this.$nextTick(this.nextStep);
        },
        updateSportsSettimanaTipo: function (sport_ids) {
            let oreSportPraticati = sport_ids.map(this.makeOreSport);
            this.settimanaTipo = this.settimanaTipo.map((giorno)=>{
                let sportsGiorno = giorno.sports || [];
                let sports = oreSportPraticati.map((sport)=>{
                    let sportSettimanaTipo = sportsGiorno.find(el=>el.sport_id == sport.sport_id);
                    if (sportSettimanaTipo) {
                        return {...sportSettimanaTipo};
                    } else {
                        return {...sport};
                    }
                });

                sports.sort(this.sortBySportId)
                return {
                    ...giorno,
                    sports: sports,
                }
            })
        },

        updateSports: function () {
            this.sportPraticati = this.sportPraticati.sort();
            this.updateSportPraticati(this.idSportPraticati);
            this.updateSportsSettimanaTipo(this.idSportPraticati);
        },
        totaleOre: function (giorno) {
            if (!(giorno.sports &&giorno.sports.length)) return 0;
            let totale = giorno.sports.reduce((acc,el)=>{
                return acc + +el.minuti
            },0);
            return this.ore(totale);
        },
        updateGiorniAndNextStep: function () {
            this.updateGiorni();
            this.$nextTick(this.nextStep);
        },
        ore: function(minuti) {
            if (!minuti) {
                return "0";
            }
            let h = Math.floor(minuti/60);
            let m = minuti-h*60;
            if (!m) return h+"h";
            return h+"h"+m+"'";
        },
        giornoInSettimanaTipo: function (giorno, settimanaTipo) {
            let giornoInSettimana = settimanaTipo.find(el=>el.giorno == giorno.id);
            return giornoInSettimana;
        },
        updateGiorni: function () {
            this.settimanaTipo = this.settimanaTipo.filter(
                (giorno)=>this.idGiorni.indexOf(giorno.giorno)!==-1
            );
            this.giorni = this.idGiorni.sort().map((id)=>{
                let giorno = this.config.giorni.find(el=>el.id == id);
                let giornoSettimanaTipo = this.settimanaTipo.find(el=>el.giorno==id && el.sports && el.sports.length);
                if (giornoSettimanaTipo) {
                    return {
                        ...giorno,
                        sports: giornoSettimanaTipo.sports,
                    }
                } else {
                    return {
                        ...giorno,
                        sports: this.makeOreSports(this.idSportPraticati),
                    }
                }

            });
            
            
        },
        updateGiorniSettimanaTipo: function () {
            this.giorni = this.giorni.map((giorno)=>{
                    let giornoSettimanaTipo =this.giornoInSettimanaTipo(giorno,this.settimanaTipo); 
                    if (giornoSettimanaTipo) {
                        return giornoSettimanaTipo;
                    } else {
                        return giorno;
                    }
                })

        }


  },
}
export default component
</script>


<style lang="scss">
    .settimana-tipo {
        .v-stepper__header {
            height: 30px;
        }

        .v-stepper__step {
            padding: 3px 10px;
        }

        .v-stepper__content {
            padding: 0;
        }

        .totale-settimana {
            display: flex;
            font-weight: bold;
            justify-content: space-between;
            background: var(--col-grigio-chiarissimo);
        }

            .item {
                --num-sports: 4;
                    display: flex;
                    justify-content: flex-start;
                    align-content: center;
                    align-items: center;
                    gap: 5px 10px;
                    padding: 2px 10px;
                    border-bottom: 1px solid var(--col-grigio-chiaro);
                    width: auto;
                    margin: auto;
                    &:after {
                        display: none;
                    }
            }
            .riposo {
                padding: 3px;
                border-radius: 4px;
                background-color: var(--col-grigio-chiaro);
                flex: 1;
                text-align: center;
            }
            .giorno {
                width: 3em;
            }
            .giorno-sport {
                display: flex;
                align-items: center;
                gap: 5px;
                .icona {
                    font-size: 1.4em;
                }
                &.vuoto {
                  display:none;
                }
                .minuti {
                    font-size:0.8em;
                }
            }
        .giorni-titolo {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr 1fr;
            line-height: 1;
                      
            .small {
                font-size: 0.8em;
                color: var(--col-grigio);
                grid-row: 2;
            }
            
            .v-chip {
                grid-column: -1;
                grid-row: span 2;
                align-self: start;
            }
        }

        .off .v-input__slider{
            opacity: 0.3;
        }
    }
</style>
