const profilo = {
        atleta: [
            {
                "key": "email",
                "label": "Email",
                "table": "utente",
                "type": "text",
                "disabled": true,
                "editing": false,
            }, 
            {
                "key": "nome",
                "label": "Nome",
                "table": "utente",
                "type": "text",
                "editing": false,
            }, 
            {
                "key": "cognome",
                "label": "Cognome",
                "table": "utente",
                "type": "text",
                "editing": false,
            }, 
            {
                "key": "sesso",
                "label": "Sesso",
                "table": "utente",
                "type": "radio",
                "options": [
                {
                    value: "f",
                    label: "f"
                },
                {
                    value: "m",
                    label: "m"
                },
                ],
                "editing": false,
            }, 
            {
                "key": "cod_fiscale",
                "label": "Codice fiscale",
                "table": "utente",
                "type": "text",
                "editing": false,
            }, 
             {
                 "key": "altezza",
                 "label": "Altezza (cm)",
                 "table": "atleta",
                 "type": "text",
                 "editing": false,
             }, 
             {
                 "key": "peso",
                 "label": "Peso (kg)",
                 "table": "atleta",
                 "type": "custom",
                 "editing": false,
             }, 
            {
                "key": "data_nascita",
                "label": "Data di nascita",
                "table": "utente",
                "type": "date",
                "editing": false,
            }, 
             
        ],
        allenatore: [
            {
                "key": "nome",
                "label": "Nome",
                "table": "utente",
                "type": "text",
                "editing": false,
            }, 
            {
                "key": "cognome",
                "label": "Cognome",
                "table": "utente",
                "type": "text",
                "editing": false,
            }, 
            {
                "key": "sesso",
                "label": "Sesso",
                "table": "utente",
                "type": "radio",
                "options": [
                {
                    value: "f",
                    label: "f"
                },
                {
                    value: "m",
                    label: "m"
                },
                ],
                "editing": false,
            }, 
            {
                "key": "cod_fiscale",
                "label": "Codice fiscale",
                "table": "utente",
                "type": "text",
                "editing": false,
            }, 
            {
                "key": "email",
                "label": "Email",
                "table": "utente",
                "type": "text",
                "editing": false,
            }, 
            {
                "key": "data_nascita",
                "label": "Data di nascita",
                "table": "utente",
                "type": "date",
                "editing": false,
            }, 
             
        ],
};


export default profilo;
