<template>
  <div class="delete-account">
<v-app-bar app dark color="grey darken-3"
      >
      <v-btn icon @click="dismissModal()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>
          Elimina account
      </v-toolbar-title>
      <v-spacer></v-spacer>
      </v-app-bar>
    <v-container>

    <h2>Attenzione!</h2>
    <p>Eliminando il tuo account verranno eliminati tutti i dati ad esso collegati.</p>
    <p>A titolo esemplificativo:</p>
    <ul class="mb-5">
        <li>dati personali</li>
        <li>misurazioni</li>
        <li>impegni, gare, allenamenti e qualsiasi altra informazione legata al calendario</li>
        <li>test atletici</li>
        <li>librerie</li>
        <li>file caricati, come immagini o documenti</li>
        <li>messaggi</li>
    </ul>
        <p><strong>Cliccando questo tasto, l'account verrà eliminato immediatamente.</strong></p>
        <p>
        <v-btn 
        block
        outlined
        color="error"
        @click="deleteAccountAndLogout">Elimina account definitivamente</v-btn>
        </p>

    </v-container>
</div>

</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";

const component = {
    mixins: [
        env,
    ],
    components: {
        mspAppBar,
    },
    data () {
        return {
        }
    },
    mounted () {
    },
    watch: {
    },
    computed: {
    },
    methods: {
        dismissModal () {
          this.$router.go(-1);
        },
        deleteAccountAndLogout: async function () {
            let deleted = this.deleteAccount();
            if (deleted) {
                this.logout();
            }
        },
        logout: function () {
          this.$router.replace({'path':'logout'});
        },
        deleteAccount: function () {
            let url = this.api_base_url+"/api/v2/utente.php";//+params;
            return new Promise((resolve,reject)=>{
                _api(url, "DELETE")
                .then((result)=>{
                        if (result.success) {
                            return true;
                        } else {
                            return false;
                        }
                    },
                    (error)=>{
                        reject(error);
                    }
                )
            });
    },
  },
}
export default component
</script>
<style lang="scss">
.delete-account {
}
</style>
