<template>
  <div class="account account-acquisti">
    
    <msp-app-bar></msp-app-bar>

    <v-container>
      <v-row dense>
        <v-col cols="12">
        <msp-licenza-scaduta></msp-licenza-scaduta>
          <v-card
          v-if="licenza"
          dark
          color="var(--col-msp)"
          >

          <v-card-title
          >

          <div>Licenza attiva</div>
          <p  class="display-1">

            {{licenza.prodotto_nome}}
          </p>
        </v-card-title>
      <v-card-actions>
        <v-chip
        dark
        >
        giorni: {{licenza.countdown}}
      </v-chip>
      <v-spacer></v-spacer>

        <v-btn
        text
        @click="revealLicenza = true"
        >
        dettagli
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <v-card
      v-if="revealLicenza"
      light
      color="var(--col-msp-chiaro)"
      class="transition-fast-in-fast-out v-card--reveal"
      style="height: 100%;"
      >
      <v-card-text class="pb-0">

    <v-list two-line>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="var(--col-msp)">
            mdi-calendar
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-subtitle>Data acquisto</v-list-item-subtitle>
          <v-list-item-title>{{licenza.data_inizio_dmY}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="var(--col-msp)">
            mdi-calendar
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-subtitle>Data scandenza</v-list-item-subtitle>
          <v-list-item-title>{{licenza.data_fine_dmY}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      </v-list>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
        text
        @click="revealLicenza = false"
        >
        Chiudi
      </v-btn>
    </v-card-actions>
  </v-card>
</v-expand-transition>
</v-card>
</v-col>
</v-row>
<v-row dense>
  <v-col cols="12">
    <v-card>
      <v-card-title>
        Acquisti
      </v-card-title>
      <v-card-text>

        <v-list two-line>
          <template v-for="acquisto in filteredAcquisti">

          <v-list-item  :key="acquisto.id">
            <v-list-item-content>
              <v-list-item-subtitle>{{convertDate(acquisto.data_acquisto)}}</v-list-item-subtitle>
              <v-list-item-title>{{acquisto.prodotto_nome}}</v-list-item-title>
            </v-list-item-content>
        <v-list-item-action>
        <v-btn @click="openModalAcquisto(acquisto.id)" v-if="acquisto.prodotto_form_id" icon>
        <v-icon>mdi-information</v-icon>
        </v-btn>
        </v-list-item-action>
          </v-list-item>
          <v-divider  :key="'divider-'+acquisto.id"></v-divider>
        </template>
      </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        text
        @click="showAcquisti = !showAcquisti"
        >
        {{textBtnAcquisti}}
      </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</v-row>
<v-dialog
scrollable
v-model="showModalAcquisto"
>
<router-view></router-view>
</v-dialog>
</v-container>
</div>

</template>

<script type="module">
import mspAppBar from "@components/MspAppBar.vue";
import mspLicenzaScaduta from "@components/MspLicenzaScaduta.vue";
import env from "@mixins/environment.js"
import _api from "@mixins/api.js";
import {  mapState } from 'vuex'
const component = {
  mixins: [
    env,
  ],
  components: {
    mspAppBar,
    mspLicenzaScaduta,
  },
  data () {
    return {
      licenza: null,
      revealLicenza: false,
      showAcquisti: false,
      showModalAcquisto: false,
      limitAcquisti: 5,
      acquisti: [],
    }
  },

    watch:    {
        dialog: function (val) {
          this.showModalAcquisto = val;
          },
    },
  mounted () {
    this.init();

  },
  computed: {
    ...mapState(
      {
        dialog: (state)=>state.acquisto.dialog,
      }
    ),
    filteredAcquisti () {
      let acquisti = [...this.acquisti];
      if (!this.showAcquisti) {
        acquisti = acquisti.slice(0, this.limitAcquisti);
      }
      return acquisti;
    },
    textBtnAcquisti () {
      return (this.showAcquisti) ? 'Nascondi' : 'Mostra tutti';
    }
  },
  methods: {
      openModalAcquisto: async function (acquisto_id) {
          this.$store.dispatch("acquisto/setDialog", true);
          this.$router.push("/account/acquisti/"+acquisto_id,true);
              this.showModalAcquisto = true;
      },
      dismissModal () {
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('account');
      },
      init () {
          if (this.dialog) {
              this.showModalAcquisto = true;
          }
          this.loadLicenza().then(
          (result)=>{
              if (result.success) {
                  this.licenza = {...result.data};
              }
          }
          );
          this.loadAcquisti().then(
          (result)=>{
              if (result.success) {
                  this.acquisti = result.data;
              }
          }
          );

      },
      convertDate (date) {
          return (new Date(date)).toLocaleDateString("it");
      },
      loadLicenza () {
          let url = this.api_base_url+"/api/v2/ecommerce.php?licenza";//+params;

          return new Promise((resolve,reject)=>{
              _api(url, "GET")
              .then((result)=>{

                  if (result.success) {
                      resolve(result);
                  } else {
                      resolve(null);
                  }
              })
              .catch((error)=>{
                  reject(error);
              });
          });
      },
      loadAcquisti () {
          let url = this.api_base_url+"/api/v2/ecommerce.php?acquisti";//+params;

          return new Promise((resolve,reject)=>{
              _api(url, "GET")
                  .then((result)=>{
                      if (result.success) {
                          resolve(result);
                      } else {
                          resolve(null);
                      }
                  })
                  .catch((error)=>{
                      reject(error);
                  });
          });
      },

  },
}
export default component
</script>
<style lang="scss">
.account {
}
</style>
